import { createSignal, onMount, Show, splitProps } from "solid-js";
import { TextField, Select, MenuItem, ListItemIcon, ListItemText, Divider, FormControl } from "@suid/material";
import { CountryType, countries } from "../DataLists/DataCountryList";
import { SelectChangeEvent } from "@suid/material/Select";
import { getText } from '../SmallComponents/Translate'

import toast, { Toaster } from 'solid-toast';


interface CurrencyValueInputProps {
    id: string;
    startValue?: string;
    label?: string;
    currencyCode?: string;
    thousandSeparator?: string;
    decimalSeparator?: string;
    numberOfDecimals?: number;
    showCurrencyCodes?: boolean;
    other?: string;
}


const FieldCurrencyValue = (props: CurrencyValueInputProps) => {
    //const [local] = splitProps(props, ['other'])

    const [open, setOpen] = createSignal(false);
    const [currencyCode, setCurrencyCode] = createSignal(props?.currencyCode ? props.currencyCode : "EUR");
    const [valueInCurrency, setValueInCurrency] = createSignal(props?.startValue ? props.startValue : "0,00");
    const [thousandSeparator, setThousandSeparator] = createSignal(props?.thousandSeparator ? props.thousandSeparator : ".");
    const [decimalSeparator, setDecimalSeparator] = createSignal(props?.decimalSeparator ? props.decimalSeparator : ",");
    const [numberOfdecimals, setNumberOfDecimals] = createSignal(props?.numberOfDecimals ? props.numberOfDecimals : 2);
    const [showCurrencyCodes, setShowCurrencyCodes] = createSignal(props?.showCurrencyCodes ? props.showCurrencyCodes : true);
    const [filteredOptions, setFilteredOptions] = createSignal<CountryType[]>([]);

    onMount(async () => {
        setValueInCurrency(props?.startValue ? props?.startValue : "0,00");

        const suggestedCountries = countries.filter(country => country.suggested);
        setFilteredOptions(suggestedCountries);
    })
    const handleFocus = () => {
        if (valueInCurrency().length < 2)
            setValueInCurrency(props?.startValue ? props?.startValue : "0,00");
    };
    const handleBlur = () => {
        if (valueInCurrency() === "") {
            setValueInCurrency(props?.startValue ? props?.startValue : "0,00");
        }
        setValueInCurrency(formatValue(valueInCurrency()));

    }

    const handleValueInCurrencyInput = (value: string) => {
        const regex = /^-?(\d*([.,]\d*)?)?-?$/;

        if (regex.test(value)) {
            let localValue = value;
            let negative = localValue.startsWith('-') || localValue.endsWith('-');

            if (value.includes(',') && value.includes('.')) {
                if (value.lastIndexOf('.') > value.lastIndexOf(',')) {
                    localValue = value.replace(/,/g, '').replace('.', ',');
                } else {
                    localValue = value.replace(/\./g, '').replace(',', '.');
                }
            } else if (value.includes(',')) {
                localValue = value.replace(',', '.');
            }

            localValue = localValue.replace(/-/g, '');

            setValueInCurrency((negative ? '-' : '') + localValue);
        }
    };

    const formatValue = (value: string) => {
        let valueAsNumber = parseFloat(value);
        if (isNaN(valueAsNumber)) {
            valueAsNumber = 0;
        }
        return (valueAsNumber < 0 ? '-' : '') + Math.abs(valueAsNumber).toLocaleString(undefined, {
            minimumFractionDigits: numberOfdecimals() as number,
            maximumFractionDigits: numberOfdecimals() as number,
        })
            .replace(",", "¤")
            .replace(".", thousandSeparator())
            .replace("¤", decimalSeparator());
    }

    const txtAddCurrency = getText("smallcomponent", "addcurrency")
    const handleCurrencyCodeChange = (event: SelectChangeEvent) => {
        // Implement functionality to choose other currencies is pending

        toast(txtAddCurrency, { duration: 5000, });

        const newValue = event.target.value;

    };
  
    const txtOthers = getText("default", "others")
    return (
        <>

            <FormControl>
                <TextField
                    required
                    id={props.id}
                    label={props.label}
                    variant="outlined"
                    size="small"
                    sx={{ textAlign: 'right' }}
                    value={valueInCurrency()}
                    onChange={(event, value) => { handleValueInCurrencyInput(value) }}
                    onFocus={handleFocus}
                    onBlur={handleBlur}


                    InputProps={{

                        startAdornment: (
                            
                                <Select
                                    open={open()}
                                    value={currencyCode()}
                                    onChange={handleCurrencyCodeChange}
                                    onClose={() => setOpen(false)}
                                    onOpen={() => setOpen(true)}
                                    variant="standard"
                                    autoWidth
                                    disableUnderline
                                    renderValue={(selected) => `${selected}`}
                                >
                                    {filteredOptions().map((option, index) => (
                                        <MenuItem
                                            value={option.currencyCode}
                                            onClick={() => {
                                                setCurrencyCode(option.currencyCode as string);
                                                setOpen(false);
                                            }}
                                        >
                                            <ListItemIcon>
                                                <img
                                                    loading="lazy"
                                                    width="20"
                                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                    alt=""
                                                />
                                            </ListItemIcon>
                                            <ListItemText>
                                                {option?.currencyName ? option.currencyName : option.label} ({option.currencyCode}) {option.currencySymbol}
                                            </ListItemText>
                                        </MenuItem>
                                    ))}
                                    <Divider />

                                    <MenuItem value={"Others"}>{txtOthers()}</MenuItem>

                                </Select>
                            

                        ),
                    }}
                />
            </FormControl>
        </>
    );
}
// onChange={(event) => handleCurrencyCodeChange(event.target.value)}
//  onChange={handleCurrencyCodeChange}

export { FieldCurrencyValue };
